@import "../util/variables";

body {
  font-family: Arial, Helvetica, sans-serif;
  color: $black;
  background-color: $white;
  margin: 0;
}

div.page-wrapper {
  display: block;
  .main-content {
    max-width: 1100px;
    min-height: 500px;
    width: 90%;
    display: block;
    margin: 100px auto 0px auto;
    padding: 15px 30px 30px 30px;
    height: 100%;
    background-color: $light1;
    padding-bottom: 0;
    border-radius: 0.5rem 0.5rem 0 0;
    word-wrap: break-word;
  }
}

label {
  display: block;
  margin: 10px 0px;
}

input {
  border-bottom: 1px $accent1 solid;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 8px 8px 8px 8px;
  border-radius: 2px 2px 0px 0px;
  font-size: 1rem;
  max-width: 400px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-bottom: 2px $accent1 solid;
  }
}

.btnPrimary {
  padding: 12px 20px;
  background-color: $main;
  border: 3px $accent1 solid;
  margin: 7px 0px;
  font-size: 16px;
  border-radius: 2px;
  color: $white;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #013878cc;
    transition: 0.5s;
  }
}

@media #{$desktop} {
  input {
    min-width: 400px;
  }
}

@media #{$tablet} {
  input {
    min-width: 400px;
  }
}

@media #{$mobile} {
  div.page-wrapper .main-content {
    width: unset;
  }

  input {
    width: 100%;
  }
}
