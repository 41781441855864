@import "../../../util/variables";

@media #{$mobile} {
}

@media #{$tablet} {
}

@media #{$desktop} {
}

footer {
  background-color: $white;
  border-top: 0.2rem solid $accent2;
  min-height: 100%;
  padding: 2rem 5rem 2rem 5rem;
  img {
    width: 2rem;
    margin-right: 0.5rem;
  }
}
