@import "../../../util/variables.scss";

.side-nav {
    position: absolute;
    top: 50px;
    left: 0;
    width: 250px;
    height: 100%;
    transition: 0.15s;
    overflow: hidden;
    display: inline-block;
    nav {
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: $dark1;
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
                a {
                    display: block;
                    color: $white;
                    text-align: center;
                    padding: 1rem 1.5rem;
                    text-decoration: none;
                }
                a:hover:not(.active) {
                    background-color: $accent1;
                }
            }
        }
    }
}

.main-nav {
    position: fixed;
    background-color: $dark1;
    margin: auto;
    top: 0;
    width: 100%;
    min-height: 50px;
    z-index: 100;
    nav {
        max-width: 1440px;
        margin: auto;
        padding: 0;
        overflow: hidden;
        ul {
            list-style-type: none;
            display: inline;
            li {
                float: left;
                a {
                    display: block;
                    color: $white;
                    text-align: center;
                    padding: 1rem 1.5rem;
                    text-decoration: none;
                }
                a:hover:not(.active) {
                    background-color: $accent1;
                }
            }
        }
    }
}

.login-button {
    max-width: 1440px;
    position: relative;
    padding-right: 20px;
    right: 0;
    button {
        &:focus {
            outline: none;
        }
        &:hover {
            padding: 8px 25px;
            border: none;
        }
        border: none;
        background: $accent1;
        float: right;
        display: block;
        font-size: 16px;
        padding: 8px 25px;
        margin: 8px;
        color: $white;
        border-radius: 50px;
    }
}

#site-menu {
    position: absolute;
    width: 240px;
    left: -300px;
    font-size: 1.2rem;
    ul {
        list-style-type: none;
        background-color: $gray1;
        height: -webkit-fill-available;
        min-height: 500px;
        padding: 0px;
        margin: auto;
        display: block;
        li {
            display: block;
            padding: 0px 15px;
            border-bottom: 1px solid;
            border-color: $dark3;
            &:first-child {
                border-top: 1px solid;
                border-color: $dark3;
            }
            &:hover {
                background-color: $accent1;
            }
            &:focus {
                background-color: $accent1;
            }
            a {
                text-decoration: none;
                display: inline-block;
                position: relative;
                padding: 15px 0px 15px;
                width: 100%;
                color: $accent1;
                &:hover {
                    color: $white;
                }
                &:active {
                    color: $white;
                }
                &:focus {
                    color: $white;
                }
            }
        }
    }
}

#site-canvas {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    -webkit-transition: 100ms ease-in;
    transition: 100ms ease-in;
    z-index: 1000;
    display: block;
    position: fixed;
}

.show-nav #site-canvas {
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
}

.menu-bar {
    width: 2rem;
    height: .3rem;
    background-color: $white;
    margin: .4rem 0;
    transition: 0.4s;
}

@media #{$desktop} {
    .main-nav {
        nav {
            button.icon {
                display: none;
            }
        }
    }
}

@media #{$tablet} {
    .main-nav {
        nav {
            button.icon {
                display: none;
            }
        }
    }
}

@media #{$mobile} {
    .main-nav {
        nav {
            button.icon {
                float: left;
                display: block;
                color: $white;
                font-size: 25px;
                padding: 5px 15px;
                border: none;
                background: $main;
                margin: 0px;
                min-height: 50px;
                &:focus {
                    outline: none;
                }
            }
            ul {
                li {
                    display: none;
                }
            }
        }
    }
}
