$font-stack: Helvetica, sans-serif;

// Theme Colors
$black: #171D22;
$white: #FFFFFF;
$dark1: #353535;
$dark2: #8B97AB;
$dark3: #d6d6d6;
$gray1: #dedede;
$light1: #eeeeee;
$main: #013878;
$accent1: #00b9ff;
$accent2: #43BFFF;

// Breakpoints
$mobile: "(max-width: 767px)";
$tablet: "(min-width: 768px) and (max-width: 1023px)";
$desktop: "(min-width: 1024px)";
