@import "../../../util/variables";

@media #{$mobile} {
  div.page-wrapper .main-content-home {
    width: unset !important;
  }
}

.main-content-home {
    h2{
        color: $white;
        font-weight: 700;
        font-size: 4rem;
        margin: 0;
        padding: 2rem;
    }
    h3{
        color: $white;
        font-weight: 300;
        font-size: 2rem;
        margin: 0rem;
        padding: 1rem;
        span{
          color: $accent1;
          background-color: $white;
        }
    }
    max-width: 1100px;
    min-height: 500px;
    width: 90%;
    text-align: center;
    display: block;
    margin: 100px auto 0px auto;
    padding: 15px 30px 30px 30px;
    height: 100%;
    background-image: url('../../../../public/banner-home.jpg');
    padding-bottom: 0;
    border-radius: 0.5rem 0.5rem 0 0;
    word-wrap: break-word;
  }